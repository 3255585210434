/* @font-face {
    font-family: 'InterTight';
    src: url(../fonts/InterTight.woff2) format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url(../fonts/Inter.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'InterLight';
    src: url(../fonts/InterLight.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'InterRegular';
    src: url(../fonts/InterRegular.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'InterSB';
    src: url(../fonts/InterSB.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
} */
@font-face {
    font-family: 'InterTight';
    src: url(../../public/fonts/InterTight.woff2) format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Inter';
    src: url(../../public/fonts/Inter.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'InterLight';
    src: url(../../public/fonts/InterLight.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'InterRegular';
    src: url(../../public/fonts/InterRegular.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'InterSB';
    src: url(../../public/fonts/InterSB.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}